/* eslint-disable no-unused-vars */
import axios from "axios";
import url from "@/helpers/url";
import Storage from "@/helpers/storage.js";

const token = `${Storage.get("currentUser").email}`;

export const state = {
  courses: [],
  ecert: [],
  enrolls: [],
  categories: [],
  users: [],
  eusers: [],
};

//action
export const actions = {
  fetchCourses({ commit }, entityId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url()}/api/green4d/course/entity/${entityId}/`, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("SET_COURSES", response.data.course);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchDashboardData({ commit }, entityId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url()}/api/green4d/dashboard/${entityId}/`, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCategories({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url()}/api/green4d/course/all-category/`, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("SET_CATEGORY", response.data);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetcheCourses({ commit }, entityId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url()}/api/green4d/course/entity_stats/${entityId}/`, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("SET_ECOURSES", response.data.course);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetcheCerts({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${url()}/api/green4d/dashboard/${data.entityId}/course-stats/${
            data.courseId
          }/`,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            commit("SET_ECERTUSERS", response.data);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchEnrollUsers({ commit }, courseId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url()}/api/green4d/individuals/enrolled-course/${courseId}/`, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("SET_ENROLLCOURSES", response.data.individuals);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchPassedUsers({ commit }, testId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url()}/api/green4d/individuals/passed-test/${testId}/`, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("PASSED_USERS", response.data.test);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCategoryCourses({ commit }, category_slug) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url()}/api/green4d/course/category/${category_slug}/`, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addNewCourse({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${url()}/api/green4d/add-course/`, data, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("ADD_COURSE", response.data);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateCourse({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${url()}/api/green4d/course/${data.courseID}/`, data.course, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("UPDATE_COURSE", {
              id: data.courseID,
              courses: response.data.course,
            });
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteCourse({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${url()}/api/green4d/course/${data.courseID}/`, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          commit("DELETE_COURSE", data.courseID);

          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

//mutaions
export const mutations = {
  ADD_COURSE(state, course) {
    state.courses.push(course);
  },
  SET_COURSES(state, courses) {
    state.courses = courses;
  },
  SET_ECERTUSERS(state, courses) {
    state.eusers = courses;
  },
  SET_CATEGORY(state, categories) {
    state.categories = categories;
  },
  SET_ECOURSES(state, courses) {
    state.ecourses = courses;
  },
  SET_ENROLLCOURSES(state, courses) {
    state.enrolls = courses;
  },
  UPDATE_COURSE(state, { id, courses }) {
    state.courses.forEach((course) => {
      if (course.id == id) {
        course.id = courses;
      }
    });
  },
  PASSED_USERS(state, users) {
    state.users = users;
  },
  DELETE_COURSE(state, courseID) {
    state.courses.filter((course) => course.id != courseID);
  },
};

// Getters
export const getters = {
  allcourses: (state) => state.courses,
  allenrollusers: (state) => state.enrolls,
  ecourses: (state) => state.ecert,
  allcategories: (state) => state.categories,
};
