/* eslint-disable no-unused-vars */
import axios from "axios";
import url from "@/helpers/url";
import Storage from "@/helpers/storage.js";

const token = `${Storage.get("currentUser").email}`;

export const state = {
  courses: [],
  mycourses: [],
  certsearch: [],
};

//action
export const actions = {
  fetchAllCourses({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url()}/api/green4d/course/all/`, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("SET_COURSES", response.data.course);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  myCourses({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url()}/api/green4d/enrolled-courses/${userId}/`, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("MY_COURSES", response.data.Enrollments);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  enrollCourse({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${url()}/api/green4d/course/${data.courseId}/enroll/?individual_id=${
            data.individualId
          }`,
          data.content,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            commit("ENROLL_COURSE", response.data.enroll);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  unEnrollCourse({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${url()}/api/green4d/course/${
            data.courseId
          }/unenroll/?individual_id=${data.individualId}`,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            commit("UNENROLL_COURSE", data.individualId);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  doneCourse({ commit }, enrollmentId) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${url()}/api/green4d/enrolled-courses/view/${enrollmentId}/done/`,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response) => {
          commit("MARK_COURSE", response.data);

          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  eCertSearch({ commit }, ecert) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url()}/api/green4d/individuals?ecert=${ecert}`, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          commit("CERT_SEARCH", response.data);

          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  eCertDownload({ commit }, ecertId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url()}/api/green4d/ecert/${ecertId}/download/`, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

//mutaions
export const mutations = {
  ENROLL_COURSE(state, course) {
    state.mycourses.push(course);
  },
  SET_COURSES(state, courses) {
    state.courses = courses;
  },
  MY_COURSES(state, courses) {
    state.mycourses = courses;
  },
  CERT_SEARCH(state, courses) {
    state.certsearch = courses;
  },
  MARK_COURSE(state, id) {
    state.mycourses.filter((course) => course.id != id);
  },
  UNENROLL_COURSE(state, individualId) {
    state.mycourses.filter((course) => course.course_id != individualId);
  },
};

// Getters
export const getters = {
  allcourses: (state) => state.courses,
  mycourse: (state) => state.mycourses,
};
