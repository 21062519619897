import Vue from "vue";
import App from "./App.vue";
import BootstrapVue, { BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueApexCharts from "vue-apexcharts";
import Vuelidate from "vuelidate";
import VueSweetalert2 from "vue-sweetalert2";
import VueMask from "v-mask";
import * as VueGoogleMaps from "vue2-google-maps";
import VueYoutube from "vue-youtube";
//NEW UPLOAD PAGE CONTENT FROM OLD SITE
import Toasted from "vue-toasted";
import VueSessionStorage from "vue-sessionstorage";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import axios from "axios";
import moment from "moment";
import vueScreen from "vue-screen";
import vueCountryRegionSelect from "vue-country-region-select";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// END OF NEW UPLOAD PAGE CONTENT FROM OLD SITE
import vco from "v-click-outside";
import router from "./router";
import store from "@/state/store";
import i18n from "./i18n";
import "@/assets/scss/app.scss";
import { configureFakeBackend } from "./helpers/fakebackend/fake-backend";
if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  //
} else {
  configureFakeBackend();
}
// USE IMPORTED PAGE PACKAGESHERE
Vue.use(vueCountryRegionSelect);
Vue.component("multiselect", Multiselect);
Vue.use(VueSessionStorage);
Vue.use(Toasted);
Vue.prototype.$http = axios;
(Vue.config.productionTip = false), Vue.use(vueScreen);
Vue.use(moment);
Vue.filter("formatDate", function(value, formatType = "LL") {
  if (!value) return "";
  return moment(value).format(formatType);
});

// END OF USE IMPORTED PAGE PACKAGES HERE
Vue.config.productionTip = false;
Vue.use(VueYoutube);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(vco);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);

Vue.use(VueMask);
Vue.use(require("vue-chartist"));
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places",
  },
  installComponents: true,
});

Vue.component("apexchart", VueApexCharts);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
