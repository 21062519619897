<template>
  <div id="app">
    <router-view />
  </div>
</template>
<style>
html,
body {
  overflow-x: hidden;
}

.content_margin_bottom {
  margin-bottom: 200px !important;
}
</style>
<script>
import appConfig from "@/app.config";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
};
</script>
