/* eslint-disable no-unused-vars */
import axios from "axios";
import url from "@/helpers/url";
import Storage from "@/helpers/storage.js";

const token = `${Storage.get("currentUser").email}`;

export const state = {
  content: [],
  test: [],
};

//action
export const actions = {
  addNewContent({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${url()}/api/green4d/course/add-content/?type=${
            data.contentType
          }&course_id=${data.courseId}`,
          data.content,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            commit("ADD_CONTENT", response.data.course);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addNewTest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${url()}/api/green4d/course/${data.courseId}/add-content/test/`,
          data.test,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            commit("ADD_TEST", response.data);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateContent({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${url()}/api/green4d/course/content/${data.contentId}/?type=${
            data.contentType
          }`,
          data.content,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            commit("UPDATE_CONTENT", {
              id: data.contentId,
              contents: response.data.content,
            });
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateTest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${url()}/api/green4d/course/content/${data.testId}/test/`,
          data.test,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  viewContent({ commit }, courseId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url()}/api/green4d/course/content/${courseId}/test/`, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("VIEW_TEST", response.data.content);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteContent({ commit }, contentId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${url()}/api/green4d/course/content/${contentId}/
`,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response) => {
          commit("DELETE_CONTENT", contentId);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteTest({ commit }, contentId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${url()}/api/green4d/course/content/${contentId}/test/
`,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response) => {
          commit("DELETE_CONTENT", contentId);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  submitTest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${url()}/api/green4d/course/content/test/${data.test_id}/submit/
`,
          data.test,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

//mutaions
export const mutations = {
  ADD_CONTENT(state, content) {
    state.content.push(content);
  },
  ADD_TEST(state, test) {
    state.test.push(test);
  },
  VIEW_TEST(state, content) {
    state.test = content;
  },
  UPDATE_CONTENT(state, { id, contents }) {
    state.content.forEach((video) => {
      if (video.id == id) {
        video.id = contents;
      }
    });
  },
  // UPDATE_TEST(state, { id, tests }) {
  // let test = state.test[0];
  // if (test.id == id) {
  //   test.id = tests;
  // }

  // state.test.filter((test) => {
  //   if (test.id == id) {
  //     test.id = tests;
  //   }
  // });
  // },
  DELETE_CONTENT(state, contentId) {
    state.content.filter((content) => content.id != contentId);
  },
};

// Getters
export const getters = {
  allcontent: (state) => state.content,
  question: (state) => state.test,
};
