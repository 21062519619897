/* eslint-disable no-unused-vars */
import Vue from "vue";
import axios from "axios";
import url from "@/helpers/url.js";
import Storage from "@/helpers/storage.js";
import VueSessionStorage from "vue-sessionstorage";
import service from "@/helpers/service";
var convert = require("xml-js");

export const state = {
  currentUser: Storage.get("currentUser"),
  entities: Storage.get("entities"),
  currentEntity: Storage.get("currentEntity"),
};

export const actions = {
  // Get entity user details after cas authentication
  getUserDetails({ commit }, email) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url()}/api/account/detail/${email}`)
        .then((response) => {
          const user = response.data;
          commit("SET_CURRENT_USER", user.individual);
          commit("SET_ENTITIES", user.entities);
          axios.defaults.headers.common["Authorization"] =
            user.individual.email;
          resolve(response);
        })
        .catch((err) => {
          console.log("userdetails error");
          reject(err);
        });
    });
  },
  // Entity sign
  validateServiceTicket({ commit, dispatch }, ticket) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${url()}/accounts/serviceValidate?service=${service()}&ticket=${ticket}`
        )
        .then((response) => {
          const xml = response.data;
          const data = convert.xml2json(xml, { compact: true, spaces: 4 });
          const user = JSON.parse(data);
          const email =
            user["cas:serviceResponse"]["cas:authenticationSuccess"]["cas:user"]
              ._text;
          dispatch("getUserDetails", email).then((response) =>
            resolve(response)
          );
        })
        .catch((err) => {
          console.log("error", err);
          reject(err);
        });
    });
  },
  // Admins logout
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url()}/accounts/logout?${service()}`)
        .then(() => {
          commit("LOGOUT");
          Storage.removeAll();
          delete axios.defaults.headers.common["Authorization"];
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  getCurrentEntity({ commit }, entity) {
    return new Promise((resolve, reject) => {
      commit("SET_CURRENT_ENTITY", entity);
      resolve(entity);
    });
  },
};

export const mutations = {
  SET_CURRENT_ENTITY(state, entity) {
    state.currentEntity = entity;
    Storage.set("currentEntity", entity);
  },
  LOGOUT(state) {
    state.currentUser = null;
    state.entities = null;
  },
  SET_CURRENT_USER(state, user) {
    state.currentUser = user;
    Storage.set("currentUser", user);
  },
  SET_ENTITIES(state, entities) {
    state.entities = entities;
    Storage.set("entities", entities);
  },
};

export const getters = {
  currentUser: (state) => state.currentUser,
  entities: (state) => state.entities,
  isLoggedIn: (state) => !!state.currentUser,
  currentEntity: (state) => state.currentEntity,
};
/* eslint-enable no-unused-vars */
